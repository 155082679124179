button:focus {
  outline: 0;
}

.btn-link {
  cursor: pointer ;
}

.mat-secondary {
  background-color: $secondary;
  color: color-yiq($secondary);
}
