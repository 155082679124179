.mat-sidenav-content::-webkit-scrollbar,
body::-webkit-scrollbar,
mat-sidenav.sidenav::-webkit-scrollbar,
.main-container::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.mat-sidenav-content::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
mat-sidenav.sidenav::-webkit-scrollbar-track,
.main-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mat-sidenav-content::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
mat-sidenav.sidenav::-webkit-scrollbar-thumb,
.main-container::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid #333;
}
