.ngx-datatable.material {
  .datatable-body {
    .datatable-body-row {
      .datatable-body-cell {
        display: flex;
        align-items: center;
      }
    }
  }
}

.ngx-datatable.material {
  .datatable-header {
    .datatable-header-cell {
      color: $primary;
    }
  }
}

.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right{
  background-color: inherit;
}
.ngx-datatable.material .datatable-header .datatable-row-right {
  background-color: #fff;
}

.mat-card-content {
  .table-filters {
    background: #f1f1f1;
    padding: 16px 16px 0 16px;
    margin: -16px -16px 16px -16px !important;
  }
}
