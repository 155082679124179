ngx-datatable {
  &.ngx-datatable.material {
    @include mat-elevation(2);
  }
}
mat-card {
  ngx-datatable {
    &.ngx-datatable.material {
      @include mat-elevation(0);
    }
  }
}
