tripetto-collector {
  display: block;
  .mat-card {
    margin-top: 16px;
    border-top: 3px solid $primary;
    box-shadow: none !important;
    border-radius: 0;
  }
  .mat-card-title {
    font-size: 16px;
  }
}

%matrix-styles-common {
  table {
    table-layout: fixed;
  }
  thead {
    //background: darken($primary, 7);
    //border-left: 1px solid darken($primary, 7);
    //border-right: 1px solid darken($primary, 7);
    //border-left: 1px solid $gray-300;
    //border-right: 1px solid $gray-300;
  }
  .mat-header-row {
    .mat-header-cell {
      //color: color-yiq(darken($primary,7));
      text-align: center;
      vertical-align: bottom;
      //font-weight: bold;
      //border-bottom: 4px solid darken($primary, 15);
      //text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
    }
  }
  .mat-header-cell-column {
    //background: $primary;
    text-align: right;
    //color: color-yiq($primary);
    //font-weight: bold;
    //text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
  }
}

.tripetto {
  &-block {
    border-top: none;
    background: $primary;
    color: color-yiq($primary);
  }
  &-matrix-radiobuttons {
    @extend %matrix-styles-common;
    .mat-radio-label {
      margin-bottom: 0;
    }
    .mat-radio-label-content {
      display: none;
    }
  }
  &-matrix-checkboxes {
    @extend %matrix-styles-common;
    .mat-checkbox-layout {
      margin-bottom: 0;
    }
  }
  &-matrix-dropdown {
    @extend %matrix-styles-common;
  }
}

.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}
