%dialog-lg {
  width: 95vw;
  height: 95vh;
  max-width: 95vw !important;
  .mat-dialog-content {
    max-height: none;
  }
}

%dialog-md {
  width: 60vw;
  height: 70vh;
  max-width: 800px !important;
  max-height: 600px !important;
  .mat-dialog-content {
    //max-height: none;
  }
}

%dialog-fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
}

.mat-dialog-content {
  min-height: 70px;
}

.dialog-lg {
  @extend %dialog-lg;
}

.dialog-md {
  @extend %dialog-md;
}

.dialog-fullscreen {
  @extend %dialog-fullscreen
}

.dialog-editor {
  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

mat-dialog-container {
  .mat-dialog-title {
    display: flex;
    align-items: center;
    &h2 {
      margin-bottom: 0;
    }
    > .mat-icon {
      margin-right: 10px;
    }
    .mat-icon-button {
      margin-left: auto;
    }
  }
}

.mat-dialog-content {
  &.not-modal {
    max-height: none;
    overflow: auto;
  }
}
