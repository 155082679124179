@import "./scss/mixins/mixins";
@import "./scss/includes/bootstrap-overrides";
@import "./scss/includes/bootsrap-core";
@import "./scss/includes/bootstrap-components";

@import './scss/includes/angular-material-survey-theme';
@import './app/component/core/theming/all-theme';
@include app-theme($ncss-app-theme);

// ngx-datatable
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';

@import '~quill/dist/quill.snow.css';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html,
body {
  min-height: 100%;
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

mat-sidenav-container {
  height: 100%;
}

// Shared Design Components
@import "scss/shared/button";
@import "scss/shared/dashboard";
@import "scss/shared/dialog";
@import "scss/shared/datatable";
@import "scss/shared/checkbox";
@import "scss/shared/forms";
@import "scss/shared/links";
@import "scss/shared/progressbars";
@import "scss/shared/scrollbar";
@import "scss/shared/sidenav";
@import "scss/shared/matTables";
@import "scss/shared/matStepper";
@import "scss/shared/tripetto-collector";
@import "scss/shared/matIcon";
@import "scss/shared/tables";
