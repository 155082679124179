.form-row {
  @extend .row;
  margin-left: -5px;
  margin-right: -5px;

  .col, [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
