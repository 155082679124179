@mixin app-message-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .app-message {
    &.mat-primary {
      color: mat-color($primary);
      background-color: mat-color($primary, 0.15);
    }

    &.mat-accent {
      color: mat-color($accent);
      background-color: mat-color($accent, 0.15);
    }
    &.mat-warn {
      color: mat-color($warn);
      background-color: mat-color($warn, 0.15);
    }
  }
}
